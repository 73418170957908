import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { removeMessage } from 'actions'
import { SNACKBAR, TOAST } from 'types'

import MaterialSnackbar from 'material-ui/Snackbar'
import IconButton from 'material-ui/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { messageShape } from 'coopPropTypes'
import Link from 'components/Link'

const CloseMessage = ({ handleClose }) => (
  <IconButton
    key='close'
    aria-label='Close'
    color='inherit'
    onClick={handleClose}
  >
    <CloseIcon />
  </IconButton>
)

const ActionLink = ({ linkDescription, linkUrl }) => {
  return (
    <Link
      href={linkUrl}
      underline={true}
      rel='noopener noreferrer'
      style={{ color: '#FFF', marginRight: '24px' }}
    >
      {linkDescription}
    </Link>
  )
}

// Modify the visual a bit so the close icon is always middle-aligned with the message
// even when the message is multi-line.
const Snackbar = styled(MaterialSnackbar)`
  display: flex !important;
  div:first-child {
    flex: 1 0 0 !important;
  }
`

const Toast = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.toHex()};
  border-radius: ${({ theme }) =>
    theme.dimensions.textInput.borderRadius.toCss()};
  color: #fff;
  display: flex;
  font-weight: 600;
  justify-content: center;
  margin-bottom: 75px;
  padding: 14px;
  width: 25vw;
`

const ToastSpan = styled.span`
  background-color: transparent;
  border: 0;
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
  padding-left: 8px;
`

const ToastText = styled.div`
  display: flex;
  gap: 8px;
  grid-column: 1 / 5;
  text-align: center;
`

const mapStateToProps = (state) => ({
  message: state.messages.find((m) => [SNACKBAR, TOAST].includes(m.type)),
})

const mapDispatchToProps = (dispatch) => ({
  removeMessage: (id) => dispatch(removeMessage(id)),
})

const SnackBarContainer = ({ message, removeMessage }) => {
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={!!message}
      autoHideDuration={6000}
      onClose={() => {
        if (message !== undefined && message.id !== undefined) {
          removeMessage(message.id)
        }
      }}
      message={message ? message.text : ''}
      action={
        <>
          {message && message.linkUrl && (
            <ActionLink
              linkDescription={message.linkDescription}
              linkUrl={message.linkUrl}
            />
          )}
          <CloseMessage handleClose={() => removeMessage(message.id)} />
        </>
      }
      style={{ marginLeft: 0 }}
    />
  )
}

const ToastContainer = ({ message, removeMessage }) => {
  const [open, setOpen] = useState(false)

  const handleClose = (_event) => {

    setOpen(false)

    setTimeout(() => {
      removeMessage(message.id)
    }, 500)
  }

  useEffect(() => {
    if (message) {
      setOpen(true)
    }
  }, [message])

  const { text, linkDescription, linkUrl } = message

  return (
    <MaterialSnackbar
      open={open}
      autoHideDuration={10 * 1000}
      onClose={handleClose}
    >
      <Toast>
        {text && <ToastText>{text}</ToastText>}
        {linkDescription && (
          <ToastSpan
            onClick={() => {
              if (!linkUrl) return

              if (typeof linkUrl === 'string') {
                window.open(linkUrl, '_blank')
              } else if (typeof linkUrl === 'function') {
                linkUrl()
              }

              handleClose()
            }}
          >
            {linkDescription}
          </ToastSpan>
        )}
      </Toast>
    </MaterialSnackbar>
  )
}

const Container = (props) => {
  if (props.message && props.message.type === TOAST) {
    return <ToastContainer {...props} />
  } else {
    return <SnackBarContainer {...props} />
  }
}

Container.propTypes = {
  message: messageShape,
}

export const Messages = connect(mapStateToProps, mapDispatchToProps)(Container)
