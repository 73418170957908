import PropTypes from 'prop-types'
import {
  VehicleType,
  VehicleBodyType,
  VehicleSize,
  VehicleDoorType,
  VehicleAxleType,
} from 'types'

export const addressPropType = PropTypes.shape({
  street_1: PropTypes.string.isRequired,
  street_2: PropTypes.string,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
})

export const locationShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  /* This is an id */
  companyID: PropTypes.string,
  lng: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
  phone: PropTypes.string,
  name: PropTypes.string.isRequired,
  address: addressPropType,
})

export const fleetAssetShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  locationID: PropTypes.string,
  assetType: PropTypes.oneOf([
    VehicleType.Truck,
    VehicleType.Tractor,
    VehicleType.Trailer,
  ]),
  size: PropTypes.oneOf([
    VehicleSize.Van,
    VehicleSize.SmallTruck,
    VehicleSize.LargeTruck,
    VehicleSize.SmallTrailer,
    VehicleSize.LargeTrailer,
  ]),
  bodyType: PropTypes.oneOf([
    VehicleBodyType.Reefer,
    VehicleBodyType.Flatbed,
    VehicleBodyType.Dry,
  ]),
  doorType: PropTypes.oneOf([VehicleDoorType.Roll, VehicleDoorType.Swing]),
  axleType: PropTypes.oneOf([
    VehicleAxleType.Single,
    VehicleAxleType.Tandem,
    VehicleAxleType.TandemWithSleeper,
  ]),
  img: PropTypes.string,
  notes: PropTypes.string,
  licensePlate: PropTypes.object,
  make: PropTypes.string,
  model: PropTypes.string,
  year: PropTypes.number,
  priceLender: PropTypes.number,
  priceBorrower: PropTypes.number,
})

export const coordinatesShape = PropTypes.shape({
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
})

export const messageShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  text: PropTypes.string,
  type: PropTypes.string,
  linkDescription: PropTypes.string,
  linkUrl: PropTypes.oneOf([PropTypes.string]),
})
