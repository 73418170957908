import React from 'react'
import { CoopThemeProvider } from '@cpbtechnology/coop-shared/ui/themes/shared/context'
import { useTheme } from 'hooks/useTheme'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { breakpoints } from './theme/breakpoints'
import { useTypeSet } from './theme/useTypeSet'
import PubNub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'

export const ThemeProvider = ({ children }: { children: JSX.Element }) => {
  const typeSet = useTypeSet()
  const theme = useTheme()
  return (
    <StyledThemeProvider theme={{ ...theme, breakpoints, typeSet }}>
      <CoopThemeProvider value={{ ...theme, typeSet }}>
        {children}
      </CoopThemeProvider>
    </StyledThemeProvider>
  )
}

const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
  subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY!,
  uuid: 'NOT_SET',
})

export const PubNubClientProvider = ({ children }: { children: JSX.Element }) => {
  return (
    <PubNubProvider client={pubnub}>{children}</PubNubProvider>
  )
}