import { fork, takeLatest, put, call } from 'redux-saga/effects'
import api from 'state/utils/api'
import { AdminCrudSagas } from './shared'
import {
  adminFetchUsers,
  adminFetchMarkets,
  adminApiCallFailed,
  adminPushIsBusy,
  adminPopIsBusy,
} from 'actions'
import { AdminError } from 'state/reducers/admin/errors'
import { addMessage } from 'actions'
import { TOAST } from 'types'
import {
  ADMIN_FETCH_USERS,
  ADMIN_CREATE_USER,
  ADMIN_UPDATE_USER,
  ADMIN_DELETE_USER,
  ADMIN_CREATE_USER_AND_SEND_VERIFY_EMAIL,
  ADMIN_SEND_VERIFY_EMAIL,
  ADMIN_FORGOT_PASSWORD,
  ADMIN_CREATE_OKTA_ACCOUNT,
} from 'types'
import { isOktaEnabled } from 'utils/okta'
import { EUserRole } from '@cpbtechnology/coop-shared/types/user'

class UsersSagas extends AdminCrudSagas {
  *sendVerifyEmail(action) {
    let model = action.payload.model
    const next = action.payload.next
    try {
      yield put(adminPushIsBusy())
      yield call([api, 'get'], `/flow/resend-verification-email/`, {
        userName: model.userName,
      })
      yield put(adminPopIsBusy())
      yield next()
    } catch (error) {
      yield put(adminPopIsBusy())
      yield put(
        adminApiCallFailed(
          new AdminError(`Send ${this.modelNames.singular} email`, error)
        )
      )
    }
  }

  // TODO: really, whether or not the email is sent should be just part of
  // the action payload, so we don't need two separate actions for this.
  *createWithEmailOrNot(action, shouldSendEmail) {
    let user = action.payload.model
    const next = action.payload.next

    try {
      if (user.role === EUserRole.owner || /@ryder.com\s*$/.test(user.userName)) {
        yield put(adminPushIsBusy())
        const { oktaResult } = yield call([api, 'post'], `/admin/users`, {
          user,
          shouldSendEmail,
        })
        if (isOktaEnabled() && !!oktaResult) {
          const { oktaStatus } = oktaResult
          if (oktaStatus === 'error') {
            yield put(
              addMessage(
                'okta-create-account-error',
                'There was an error with Okta account creation. You can try again later when editing the user details',
                TOAST
              )
            )
          }
        }
        yield put(adminPopIsBusy())
        yield next()
      } else {
        yield put(
          addMessage(
            'okta-create-account-error',
            'Only @ryder.com emails are allow',
            TOAST
          )
        )
      }
    } catch (error) {
      yield put(adminPopIsBusy())
      yield put(
        adminApiCallFailed(
          new AdminError(`Create ${this.modelNames.singular}`, error)
        )
      )
    }
  }

  // Override create so we always use the /admin endpoint for this.
  // TODO: really, whether or not the email is sent should be just part of
  // the action payload, so we don't need two separate actions for this.
  *create(action) {
    yield this.createWithEmailOrNot(action, false)
  }

  // TODO: really, whether or not the email is sent should be just part of
  // the action payload, so we don't need two separate actions for this.
  *createUserAndSendVerifyEmail(action) {
    yield this.createWithEmailOrNot(action, true)
  }

  // Override update so we always use the /admin version.
  *update(action) {
    let { modelId } = action.payload
    yield super.update(action, { endpoint: `/admin/users/${modelId}` })
  }

  *forgotPassword(action) {
    let model = action.payload.model
    try {
      yield put(adminPushIsBusy())
      yield call([api, 'post'], `/flow/init-reset-password`, {
        username: model.userName,
      })
      yield put(adminPopIsBusy())
      yield put(
        addMessage(
          'init-reset-password-success',
          'Create password email sent to user',
          TOAST
        )
      )
    } catch (error) {
      yield put(adminPopIsBusy())
      yield put(
        adminApiCallFailed(
          new AdminError(`Send ${this.modelNames.singular} email`, error)
        )
      )
    }
  }

  *createOktaAccount(action) {
    let { modelId, onComplete, onError } = action.payload
    try {
      yield put(adminPushIsBusy())
      const response = yield call(
        [api, 'post'],
        `/admin/users/${modelId}/create-okta-account`
      )
      const { oktaStatus } = response
      yield put(
        addMessage(
          'okta-create-account-success',
          oktaStatus === 'registered'
            ? 'User already has an Okta account'
            : 'Okta account has been created successfully!',
          TOAST
        )
      )
      if (onComplete) {
        onComplete()
      }
    } catch (error) {
      yield put(
        adminApiCallFailed(new AdminError(`Creating Okta Account`, error))
      )
      if (onError) {
        onError(error)
      }
    } finally {
      yield put(adminPopIsBusy())
    }
  }
}
const sagas = new UsersSagas({
  modelNames: { singular: 'user', plural: 'users' },
  endpoint: '/crud/users',
  fetchAction: adminFetchUsers,
  preFetchActions: [adminFetchMarkets],
  updateMethod: 'patch',
  apiVersion: 'v2',
})

export function* init() {
  yield fork(function* () {
    yield takeLatest(ADMIN_FETCH_USERS, sagas.fetch.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_CREATE_USER, sagas.create.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_UPDATE_USER, sagas.update.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_DELETE_USER, sagas.delete.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_FORGOT_PASSWORD, sagas.forgotPassword.bind(sagas))
  })
  yield fork(function* () {
    yield takeLatest(
      ADMIN_CREATE_OKTA_ACCOUNT,
      sagas.createOktaAccount.bind(sagas)
    )
  })
  yield fork(function* () {
    yield takeLatest(
      ADMIN_CREATE_USER_AND_SEND_VERIFY_EMAIL,
      sagas.createUserAndSendVerifyEmail.bind(sagas)
    )
  })
  yield fork(function* () {
    yield takeLatest(ADMIN_SEND_VERIFY_EMAIL, sagas.sendVerifyEmail.bind(sagas))
  })
}

export const routeTrigger = sagas.routeTrigger.bind(sagas)
